import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// import NacrtMalaRiva from "../svg/nacrt-baska.svg"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

// placeholderImage: file(relativePath: { eq: "baska-interpolacija-v1.jpg" }) {

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "baska-2023-04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1366) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="alignwide project-banner">
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      {/* <section className="project-banner-detalj"></section>
  <NacrtMalaRiva className="project-banner-detalj-malariva"/> */}
    </div>
  )
}

export default Image
