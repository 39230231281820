import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
// import PropTypes from "prop-types"

const Sidebar = () => {
  const data = useStaticQuery(graphql`
  query MyQuery {
    allMdx(sort: {fields: frontmatter___datum, order: DESC}) {
      edges {
        node {
          frontmatter {
            datum(formatString: "DD. MM. YYYY.")
            promoslika {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            slug
          }
          id
          excerpt
        }
      }
    }
  }
`)

  return (
    <aside id="sidebar">

      <h2 className="podnaslov-markiran">Objave</h2>
      <section id="objave">
        {data.allMdx.edges.map(({ node }) => (
          <article key={node.id} className="objava-preview">
            {/* {node.frontmatter.promoslika} */}
            <Img className="objava-preview-slika"
              fluid={node.frontmatter.promoslika.childImageSharp.fluid}
              alt="Promo picture"
            />
            <p className="objava-preview-datum">{node.frontmatter.datum}</p>
            <Link to={`/${node.frontmatter.slug}/`}><h4>{node.frontmatter.title}</h4></Link>
            <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
          </article>
        ))}
      </section>

    </aside>
  )
}


Sidebar.propTypes = {
}

Sidebar.defaultProps = {
}

export default Sidebar



