import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"

import IconCal from "../svg/icon-cal.svg"
import IconValue from "../svg/icon-value.svg"
import IconPie from "../svg/icon-pie.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Dogradnja luke Baška" />
    <main id="main">
      <Image />

      <h1>Projekt &bdquo;Dogradnja luke Ba&scaron;ka&ldquo;</h1>
      <p>
        Projekt <strong>&bdquo;Dogradnja luke Ba&scaron;ka&ldquo;</strong>{" "}
        financiran je iz Operativnog programa Konkurentnost i kohezija 2014.
        &ndash; 2020. temeljem prijave na Drugi poziv za sufinanciranje
        unaprjeđenja lučke infrastrukture u funkciji obalnog linijskog pomorskog
        prometa.
      </p>
      <p>
        Projektom dogradnje luke Ba&scaron;ka postojeći pristan će se dograditi
        i osigurati nužnu za&scaron;titu akvatorija unutar lučkog područja luke
        Ba&scaron;ka, te omogućiti odgovarajući privez i sigurnost na vezu za
        linijske brodove u cjelogodi&scaron;njoj linijskoj plovidbi. Također će
        se podignuti razina usluge za korisnike javnih brodskih linija, u i oko
        luke, prvenstveno u smislu uređenja prilaza za osobe s invaliditetom,
        ali i dostupnosti putničkog terminala vozilima dostave i hitnih službi.
      </p>
      <section className="fact-3">
        <div className="fact">
          <IconCal className="ikona" />
          <h3>
            <span className="fact-heading">Razdoblje provedbe Projekta:</span>
            <br />
            9. rujna 2019. -<br /> 9. prosinca 2023.
          </h3>
        </div>
        <div className="fact">
          <IconValue className="ikona" />
          <h3>
            <span className="fact-heading">Ukupna vrijednost Projekta:</span>
            <br />
            41.166.298,55 HRK
          </h3>
        </div>
        <div className="fact">
          <IconPie className="ikona" />
          <h3>
            <span className="fact-heading">EU sufinanciranje projekta:</span>
            <br />
            39.709.975,94 HRK
          </h3>
        </div>
      </section>
      <p className="centriran">
        <Link className="buton-link" to="/o-projektu/">
          Saznaj više o projektu
        </Link>
      </p>
    </main>
    <Sidebar />
  </Layout>
)

export default IndexPage
